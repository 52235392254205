<template v-if="me">
  <div>
    <v-spacer></v-spacer>
    <v-card class="mt-3">
      <v-card-title>
        <v-text-field v-model="search" placeholder="Search..."></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="documents.records"
        :loading="isLoading"
        :search="search"
        transition="scale-transition"
      >
        <template v-slot:top>
          <v-btn
            class="mt-2"
            absolute
            right
            color="primary"
            small
            @click="$router.push('/user/document/0/edit')"
            >New Document</v-btn
          >
        </template>
        <template v-slot:item.name="{ item }">
          <h4 color="primary">
            <router-link :to="'/user/document/' + item.id">{{
              item.name
            }}</router-link>
          </h4>
        </template>
        <template v-slot:item.docClass="{ item }">
          <v-chip v-if="item.docClass"
            label
            dark
            :color="
              item.docClass == 'personal'
                ? 'primary'
                : item.docClass == 'business'
                ? 'secondary'
                : 'accent'
            "
            >{{ item.docClass.toUpperCase() }}</v-chip
          >
        </template>
        <template v-slot:item.documentLink="{ item }">
          <a :href="item.documentLink">Download</a>
        </template>
        <template v-slot:item.createdAt="{ item }">
          <h3 color="primary">{{ item.createdAt | getFormattedDate }}</h3>
        </template>
        <template v-slot:item.updatedAt="{ item }">
          <h3 color="primary">{{ item.updatedAt | getFormattedDate }}</h3>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon color="primary" small class="mr-2" @click="editItem(item)"
            >mdi-pencil</v-icon
          >
          <v-icon color="error" small @click="deleteItem(item)"
            >mdi-delete</v-icon
          >
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { gql } from "apollo-boost";

export default {
  name: "ViewDocuments",
  data() {
    return {
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Document Class", value: "docClass" },
        { text: "Category", value: "category" },
        { text: "Document Link", value: "documentLink" },
        { text: "Created Date", value: "createdAt" },
        { text: "Updated Date", value: "updatedAt" },
        { text: "Actions", align: "right", sortable: false, value: "actions" },
      ],
      search: "",
      documents: [],
      totalRecords: 0,
      isLoading: false,
      me: "",
    };
  },

  apollo: {
    me: gql`
      query {
        me {
          id
          branch
          email
          firstName
          lastName
          role
          branch
          branchName
        }
      }
    `,
    documents: {
      query: gql`
        query Documents($limit: Int!, $skip: Int!, $query: JSON!) {
          documents(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              name
              docClass
              category
              documentLink
              createdAt
              updatedAt
            }
            count
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        const self = this;
        const limit = 9999;
        const skip = 0;
        return {
          limit: limit,
          skip: skip,
          query: {},
          query: { user: self.me.id },
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
    },
  },

  methods: {
    editItem(item) {
      this.$router.push(`/user/document/${item.id}/edit`);
    },
    deleteItem(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#b3a369",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.value) {
          try {
            const response = await this.$apollo.mutate({
              mutation: gql`
                mutation($id: ID!) {
                  deleteDocument(id: $id) {
                    id
                    deleted
                  }
                }
              `,
              variables: {
                id: item.id,
              },
            });

            if (response) {
              this.$swal(
                "Success!",
                "Document was successfully deleted",
                "success"
              );
              this.$router.go(this.$route.currentRoute);
            }
          } catch (e) {
            console.log(`Deletion Failed ${JSON.stringify(e)}`);
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
